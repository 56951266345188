import { DateTime } from 'luxon';
import { AttachmentData } from 'models/attachment';
import { BoxIntegrationData } from 'models/box-integration';
import { ImageData } from 'models/image';
import { User as UserData } from 'models/user';
import { uniqueId } from 'hooks/useUniqueId';
import { stripHtml } from 'utility/text';
import { Video } from 'models/video';
import { IframeData, IframeMode } from 'models/iframe';
import { UPLOAD } from 'components/publisher/blocks/forms/fields/shared/SourceMenuConst';
import * as donkey from '../donkey';
import {
  blockHasExLiquidVariables,
  personalizationVariableRegExp,
} from '../liquid-variable';

export * from '../donkey';

export const getImageValue = (
  image: donkey.ImageFieldData,
  variables?: donkey.RenderingVariables
): string => {
  let url;
  // handle case where image field does not have processed and input_url properties
  // but has valid hosted_url
  if (donkey.validLegacyImageField(image)) url = image.hosted_url;
  else url = image.processed ? image.hosted_url : image.input_url;

  return donkey.getStringValue(url, variables) ?? '';
};

export const defaultBlocks: donkey.DefinitionBlock[] = [];

export const EMPTY_BLOCK = {
  name: '',
  display_name: '',
  definition: {
    version: 0,
    fields: [],
    preview: { url: '', data: {} },
    default_data: {},
    data: {},
  },
};

export function definitionToData<T extends donkey.FieldData>(
  definition: donkey.DefinitionBlock<T>
): donkey.DataBlock<T> {
  return {
    name: definition.name,
    version: definition.version,
    field_data: definition.field_data,
    format_data: definition.format_data,
    style_data: definition.style_data,
    target: definition.target,
  };
}

export function isFieldType(
  data: donkey.FieldType | unknown
): data is donkey.Field {
  if (!data) return false;
  if (typeof data === 'number') return false;
  if (typeof data === 'boolean') return false;
  if (typeof data === 'string') return false;
  if (Array.isArray(data)) return false;
  return true;
}

export function isBlockFieldData(
  data: donkey.FieldData | unknown
): data is donkey.FieldData {
  return Object.keys(data as donkey.FieldData).some((key) => {
    const child = (data as donkey.FieldData)[key];
    if (!Array.isArray(child) && typeof child === 'object')
      return child.type !== undefined;
    if (Array.isArray(child)) return child[0].type !== undefined;
    return false;
  });
}

// IFRAME
const IFRAME: donkey.IframeFieldData = {
  type: 'html',
  value:
    '<iframe src="https://example.com/" width="100%" height="400px"></iframe>',
  image: {
    is_placeholder: true,
    hosted_url: donkey.hostedUrls.blankImage,
    alt_text: '',
    type: 'image',
    input_url: donkey.hostedUrls.blankImage,
    processed: true,
  },
};

const urlGeneratedIframeClassName = 'url-generated-iframe';

export function isIframeFieldData(
  data: donkey.FieldType | unknown
): data is donkey.IframeFieldData {
  return isFieldType(data) && (data as donkey.Field).type === 'html';
}

export function toIframeFieldData(
  data: donkey.FieldType | unknown
): donkey.IframeFieldData {
  if (!isIframeFieldData(data)) return { ...IFRAME };
  return { ...IFRAME, ...data };
}

export function iframeToField(data: IframeData): donkey.IframeFieldData {
  const html =
    data.mode === IframeMode.rawHtml
      ? data.rawHtml ?? IFRAME.value
      : `<iframe src="${
          data.url ?? 'https://example.com'
        }" width="100%" height="400px" class='${urlGeneratedIframeClassName}'></iframe>`;
  return {
    type: 'html',
    value: html,
    image: data.image,
  };
}

export function extractIframeFromHtml(
  value?: string
): HTMLIFrameElement | undefined {
  if (!value) return undefined;

  let parsedHtml;
  try {
    parsedHtml = new DOMParser().parseFromString(value, 'text/html');
  } catch {
    return undefined;
  }

  if (
    !parsedHtml ||
    parsedHtml.getElementsByTagName('body').length === 0 ||
    parsedHtml.getElementsByTagName('body')[0].childElementCount !== 1 ||
    parsedHtml.getElementsByTagName('body')[0].firstElementChild?.tagName !==
      'IFRAME'
  ) {
    return undefined;
  }

  return parsedHtml.getElementsByTagName('iframe')[0];
}

export function iframeFieldToData(field: donkey.IframeFieldData): IframeData {
  const defaultData = {
    mode: IframeMode.url,
    url: 'https://example.com',
    rawHtml:
      '<iframe src="https://example.com" width="100%" height="400px"></iframe>',
    image: IFRAME.image,
  };

  const iframe = extractIframeFromHtml(field.value);
  if (!iframe) {
    return defaultData;
  }

  if (!iframe.className.includes(urlGeneratedIframeClassName)) {
    return {
      mode: IframeMode.rawHtml,
      rawHtml: field.value,
      url: defaultData.url,
      image: field.image,
    };
  }
  return {
    mode: IframeMode.url,
    rawHtml: defaultData.rawHtml,
    url: iframe.src,
    image: field.image,
  };
}

// ATTACHMENTS
const ATTACHMENT: donkey.AttachmentFieldData = {
  type: 'attachment',
  file_url: '',
  name: '',
  extension: '',
  size: 0,
  attachment_id: '',
  attachment_uuid: '',
  status: '',
};
function isAttachmentFieldData(
  data: donkey.FieldType | unknown
): data is donkey.AttachmentFieldData {
  return isFieldType(data) && data.type === 'attachment';
}
export function toAttachmentFieldData(
  data: donkey.FieldType | unknown
): donkey.AttachmentFieldData {
  if (!isAttachmentFieldData(data)) return { ...ATTACHMENT };
  return { ...ATTACHMENT, ...data };
}
export function attachmentToField(
  upload: AttachmentData
): donkey.AttachmentFieldData {
  return {
    type: 'attachment',
    size: upload.filesize,
    name: upload.filename,
    extension: upload.filetype,
    file_url: upload.publicUrl,
    status: upload.status,
    attachment_id: `${upload.id}`,
    attachment_uuid: `${upload.uuid}`,
  };
}

export const attachmentFieldToData = (
  programId: number,
  field?: donkey.AttachmentFieldData
): AttachmentData | undefined =>
  field?.size
    ? {
        id: field.attachment_id,
        uuid: field.attachment_uuid,
        url: field.file_url,
        filename: field.name,
        programId,
        filetype: field.extension,
        filesize: field.size,
        status: field.status,
        publicUrl: field.file_url,
      }
    : undefined;

export const blankImageUrl = donkey.hostedUrls.blankImage;

const IMAGE: donkey.ImageFieldData = {
  type: 'image',
  alt_text: '',
  hosted_url: '',
  input_url: '',
  processed: true,
  source: UPLOAD,
};

// BOX_INTEGRATIONS
const BOX_INTEGRATION: donkey.BoxIntegrationFieldData = {
  type: 'box-integration',
  file_url: '',
  name: '',
  description: '',
  call_to_action: '',
  extension: '',
  resource_id: '',
  resource_type: 'file',
  image: { ...IMAGE },
};
function isBoxIntegrationFieldData(
  data: donkey.FieldType | unknown
): data is donkey.BoxIntegrationFieldData {
  return isFieldType(data) && data.type === 'box-integration';
}
export function toBoxIntegrationFieldData(
  data: donkey.FieldType | unknown
): donkey.BoxIntegrationFieldData {
  if (!isBoxIntegrationFieldData(data)) return { ...BOX_INTEGRATION };
  return { ...BOX_INTEGRATION, ...data };
}
export function boxIntegrationToField(
  upload: BoxIntegrationData
): donkey.BoxIntegrationFieldData {
  return {
    ...upload,
    type: 'box-integration',
    image: imageToField(upload.image),
  };
}

export const boxIntegrationFieldToData = (
  programId: number,
  field?: donkey.BoxIntegrationFieldData
): BoxIntegrationData | undefined =>
  field
    ? {
        file_url: field.file_url,
        name: field.name,
        description: field.description,
        call_to_action: field.call_to_action,
        extension: field.extension,
        resource_id: field.resource_id,
        resource_type: field.resource_type,
        image: fieldToImage(field.image),
      }
    : undefined;

// DATES
const DATE: donkey.DateFieldData = {
  type: 'date',
  value: Math.round(new Date().getTime() / 1000),
};
function isDateFieldData(
  data: donkey.FieldType | unknown
): data is donkey.DateFieldData {
  return isFieldType(data) && data.type === 'date';
}
export function toDateFieldData(
  data: donkey.FieldType | unknown
): donkey.DateFieldData {
  if (!isDateFieldData(data)) return { ...DATE };
  return { ...DATE, ...data };
}
export function dateToField(date: DateTime): donkey.DateFieldData {
  return {
    type: 'date',
    value: Math.round(date.toSeconds()),
  };
}
export function fieldToDate(date: donkey.DateFieldData): DateTime {
  return date?.value ? DateTime.fromMillis(date.value * 1000) : DateTime.now();
}

// IMAGES
export function isImageFieldData(
  data: donkey.FieldType | unknown
): data is donkey.ImageFieldData {
  return isFieldType(data) && data.type === 'image';
}

function isImageProcessing(data: donkey.ImageFieldData): boolean {
  return (
    (data.processed !== undefined ? !data.processed : false) &&
    (data.hosted_url !== '' || data.input_url !== '')
  );
}

export function isImageFieldDataArray(
  data: unknown
): data is Array<donkey.ImageFieldData> {
  return Array.isArray(data) && !data.some((link) => !isImageFieldData(link));
}

function isImageArrayProcessing(data: donkey.ImageFieldData[]): boolean {
  return data.some(isImageProcessing);
}

export function toImageFieldData(
  data: donkey.FieldType | unknown
): donkey.ImageFieldData {
  if (!isImageFieldData(data)) return { ...IMAGE };
  return { ...IMAGE, ...data };
}
export function fieldToImage(
  img: donkey.ImageFieldData,
  variables?: donkey.RenderingVariables
): ImageData {
  return {
    altText: `${img.alt_text}`,
    url: getImageValue(img, variables),
    imageId: img.image_id,
    isPlaceholder: img.is_placeholder,
    processed: img.processed,
    href: img.href,
    openInNewTab: img.open_in_new_tab,
    source: img.source,
  };
}
export function imageToField(img?: ImageData): donkey.ImageFieldData {
  return {
    type: 'image',
    alt_text: img?.altText ? img.altText : '',
    hosted_url: img?.processed ? img.url.replace(/\s/g, '%20') : '',
    input_url: img && !img.processed ? img.url.replace(/\s/g, '%20') : '',
    image_id: img?.imageId,
    processed: !!img?.processed,
    href: img?.href,
    open_in_new_tab: img?.openInNewTab,
    is_placeholder: img?.isPlaceholder,
    source: img?.source,
  };
}
export function toImagesFieldData(
  data: donkey.FieldType | unknown
): donkey.ImageFieldData[] {
  if (!Array.isArray(data)) return [];
  return data.map(toImageFieldData);
}
export function imagesToFields(images: ImageData[]): donkey.ImageFieldData[] {
  return images.map(imageToField);
}
export function fieldsToImages(
  images: donkey.ImageFieldData[],
  variables?: donkey.RenderingVariables
): ImageData[] {
  return images.map((img) => fieldToImage(img, variables));
}

export type LinkData = Omit<
  donkey.LinkFieldData,
  'image' | 'images' | 'type' | 'processed'
> & {
  image: ImageData;
  images: ImageData[];
  requestedUrl?: string;
  type: donkey.LinkType;
};

export type ButtonLinkData = {
  type: 'button-link';
  title: string;
};

export type SocialData = Omit<
  donkey.SocialFieldData,
  'image' | 'images' | 'type' | 'processed'
> & {
  image: ImageData;
  images: ImageData[];
  requestedUrl?: string;
  type: donkey.SocialType;
};

// LINKS
export const LINK: donkey.LinkFieldData = {
  type: 'link',
  processed: false,
  url: '',
  title: {
    type: 'plain-text',
    value: '',
  },
  description: {
    type: 'plain-text',
    value: '',
  },
  callToAction: {
    type: 'plain-text',
    value: '',
  },
  image: { ...IMAGE },
  images: [],
  openInNewTab: true,
  uuid: '',
};

// BUTTON LINK
const BUTTON_LINK: donkey.ButtonLinkFieldData = {
  type: 'button-link',
  title: '',
  url: '',
  openInNewTab: true,
};

export function isButtonLinkFieldData(
  data: donkey.FieldType | unknown
): data is donkey.ButtonLinkFieldData {
  return isFieldType(data) && data.type === 'button-link';
}

export function toButtonLinkFieldData(
  data: donkey.FieldType | unknown
): donkey.ButtonLinkFieldData {
  if (!isButtonLinkFieldData(data)) return { ...BUTTON_LINK };
  return { ...BUTTON_LINK, ...data };
}

// SOCIALS
export const SOCIAL: donkey.SocialFieldData = {
  type: 'social',
  processed: false,
  url: '',
  title: {
    type: 'plain-text',
    value: '',
  },
  description: {
    type: 'plain-text',
    value: '',
  },
  callToAction: {
    type: 'plain-text',
    value: '',
  },
  image: { ...IMAGE },
  images: [],
  openInNewTab: true,
  uuid: '',
};

export function isLinkFieldData(
  data: donkey.FieldType | unknown
): data is donkey.LinkFieldData {
  return isFieldType(data) && data.type === 'link';
}

export function toLinkFieldData(
  data: donkey.FieldType | unknown
): donkey.LinkFieldData {
  if (!isLinkFieldData(data)) return { ...LINK };
  return {
    ...LINK,
    ...data,
  };
}

export function isSocialFieldData(
  data: donkey.FieldType | unknown
): data is donkey.SocialFieldData {
  return isFieldType(data) && data.type === 'social';
}

export function toSocialFieldData(
  data: donkey.FieldType | unknown
): donkey.SocialFieldData {
  if (!isSocialFieldData(data)) return { ...SOCIAL };
  return {
    ...SOCIAL,
    ...data,
  };
}

export function isLinksFieldDataArray(
  data:
    | donkey.Field[]
    | donkey.Field
    | string
    | donkey.LinkFieldData
    | donkey.LinkFieldData[]
): data is Array<donkey.LinkFieldData> {
  return Array.isArray(data) && !data.some((link) => !isLinkFieldData(link));
}

function isLinksImageProcessing(data: donkey.LinkFieldData[]): boolean {
  return data.some((link) => isImageProcessing(link.image));
}

export function isSocialsFieldDataArray(
  data:
    | donkey.Field[]
    | donkey.Field
    | string
    | donkey.SocialFieldData
    | donkey.SocialFieldData[]
): data is Array<donkey.SocialFieldData> {
  return Array.isArray(data) && !data.some((link) => !isSocialFieldData(link));
}

export function linkToField(
  link: LinkData | SocialData
): donkey.LinkFieldData | donkey.SocialFieldData {
  if (link.type === 'link') {
    return {
      type: link.type,
      processed: false,
      url: link.url || '',
      title: { type: 'plain-text', value: link.title.value },
      callToAction: {
        type: 'plain-text',
        value: link.callToAction?.value || '',
      },
      description: { type: 'plain-text', value: link.description.value },
      image: imageToField(link.image),
      images: imagesToFields(link.images),
      uuid: link.uuid || uniqueId(),
      openInNewTab: link.openInNewTab,
      readTimeInSeconds: link.readTimeInSeconds,
    };
  }
  return {
    type: link.type,
    processed: false,
    url: link.url || '',
    title: { type: 'plain-text', value: link.title.value },
    image: imageToField(link.image),
    images: imagesToFields(link.images),
    callToAction: {
      type: 'plain-text',
      value: link.callToAction?.value || '',
    },
    openInNewTab: link.openInNewTab,
    description: { type: 'plain-text', value: link.description.value },
    uuid: link.uuid || uniqueId(),
  };
}

export function fieldToLink(link: donkey.LinkFieldData): LinkData {
  return {
    title: { type: 'plain-text', value: stripHtml(link.title.value) },
    description: {
      type: 'plain-text',
      value: stripHtml(link.description.value),
    },
    url: link.url,
    callToAction: {
      type: 'plain-text',
      value: stripHtml(link.callToAction.value),
    },
    openInNewTab: link.openInNewTab,
    image: fieldToImage(link.image),
    images: fieldsToImages(link.images),
    uuid: link.uuid || uniqueId(),
    type: 'link',
    readTimeInSeconds: link.readTimeInSeconds,
  };
}

export function fieldToSocial(social: donkey.SocialFieldData): SocialData {
  return {
    title: { type: 'plain-text', value: stripHtml(social.title.value) },
    url: social.url,
    image: fieldToImage(social.image),
    images: fieldsToImages(social.images),
    uuid: social.uuid,
    callToAction: {
      type: 'plain-text',
      value: stripHtml(social.callToAction.value),
    },
    description: {
      type: 'plain-text',
      value: stripHtml(social.description.value),
    },
    openInNewTab: social.openInNewTab,
    type: 'social',
  };
}

export function toLinksFieldData(
  data: donkey.FieldType | unknown
): donkey.LinkFieldData[] {
  if (!Array.isArray(data)) return [];
  return data.map(toLinkFieldData);
}
export function toSocialsFieldData(
  data: donkey.FieldType | unknown
): donkey.SocialFieldData[] {
  if (!Array.isArray(data)) return [];
  return data.map(toSocialFieldData);
}

export function linksToFields(
  links: (LinkData | SocialData)[]
): (donkey.LinkFieldData | donkey.SocialFieldData)[] {
  return links.map(linkToField);
}
export function fieldsToLinks(
  links: (donkey.LinkFieldData | donkey.SocialFieldData)[]
): (LinkData | SocialData)[] {
  if (isLinksFieldDataArray(links)) {
    return links.map(fieldToLink);
  }
  if (isSocialsFieldDataArray(links)) {
    return links.map(fieldToSocial);
  }
  return [];
}

// Complex Rich Text
export function isRTHFieldData(
  data: donkey.FieldType | unknown
): data is donkey.ComplexRichTextFieldData {
  return isFieldType(data) && data.type === 'complex-rich-text';
}
export function isRTHFieldDataArray(
  data:
    | donkey.Field[]
    | donkey.Field
    | string
    | donkey.ComplexRichTextField
    | donkey.ComplexRichTextField[]
): data is Array<donkey.ComplexRichTextFieldData> {
  return Array.isArray(data) && !data.some((text) => !isRTHFieldData(text));
}

// USERS
const USER: donkey.UserFieldData = {
  type: 'user',
  first_name: '',
  last_name: '',
  display_name: '',
  id: '0',
};
function isUserFieldData(
  data: donkey.FieldType | unknown
): data is donkey.UserFieldData {
  return isFieldType(data) && data.type === 'user';
}
export function toUserFieldData(
  data: donkey.FieldType | unknown
): donkey.UserFieldData {
  if (!isUserFieldData(data)) return { ...USER };
  return { ...USER, ...data };
}
export function userToField(user: UserData): donkey.UserFieldData {
  return {
    type: 'user',
    id: `${user.id}`,
    first_name: `${user.firstName}`,
    last_name: `${user.lastName}`,
    display_name: `${user.displayName}`,
  };
}
export function fieldToUser(user: donkey.UserFieldData): UserData {
  return {
    id: parseInt(user.id, 10),
    firstName: user.first_name,
    lastName: user.last_name,
    displayName: user.display_name,
    accessibleBrands: [],
    accessiblePrograms: [],
  };
}
export function toUsersFieldData(
  data: donkey.FieldType | unknown
): donkey.UserFieldData[] {
  if (!Array.isArray(data)) return [];
  return data.map(toUserFieldData);
}
export function usersToFields(users: UserData[]): donkey.UserFieldData[] {
  return users.map(userToField);
}
export function fieldsToUsers(users: donkey.UserFieldData[]): UserData[] {
  return users.map(fieldToUser);
}

const POLL: donkey.PollFieldData = {
  type: 'poll',
  preset: 'single',
  question: '',
  options: [],
  processed: false,
  show_results_to_user: false,
};
export function isPollFieldData(
  data: donkey.FieldType | unknown
): data is donkey.PollFieldData {
  return isFieldType(data) && data.type === 'poll';
}
export function toPollFieldData(
  data: donkey.FieldType | unknown
): donkey.PollFieldData {
  if (!isPollFieldData(data)) return { ...POLL };
  return { ...POLL, ...data };
}

// VIDEOS

export const VIDEO: donkey.VideoFieldData = {
  type: 'video',
  video_id: undefined,
  preview_image_url:
    'https://lib.onfirstup.com/content_blocks/defaults/vid-placeholder.png',
  processed: false,
};

export function videoToField(video: Video): donkey.VideoFieldData {
  return {
    type: 'video',
    video_id: video.id,
    video_length_sec: video.durationInSeconds,
    video_uuid: video.uuid,
    custom_preview_image_url: video.customPreviewImageUrl,
    preview_image_url: video.previewImageUrl ?? '',
    image_alt_text: video.altText,
    processed: true,
    sourceType: video.sourceType as 'upload' | 'external',
    video_height: video.height,
    video_width: video.width,
  };
}

export function toVideoFieldData(
  data: donkey.FieldType | unknown
): donkey.VideoFieldData {
  return data as donkey.VideoFieldData;
}

export function isVideoFieldData(
  data: donkey.FieldType | unknown
): data is donkey.VideoFieldData {
  return isFieldType(data) && data.type === 'video';
}

export function isSimpleTextFieldData(
  data: donkey.FieldType | unknown
): data is donkey.SimpleTextFieldData {
  return isFieldType(data) && data.type === 'simple-text';
}

export function isPlainTextFieldData(
  data: donkey.FieldType | unknown
): data is donkey.PlainTextFieldData {
  return isFieldType(data) && data.type === 'plain-text';
}

export function isRichTextFieldData(
  data: donkey.FieldType | unknown
): data is donkey.RichTextFieldData {
  return isFieldType(data) && data.type === 'rich-text';
}

export function isTextFieldData(
  data: donkey.FieldType | unknown
): data is donkey.TextFieldData {
  return (
    isSimpleTextFieldData(data) ||
    isRichTextFieldData(data) ||
    isPlainTextFieldData(data)
  );
}

export function isInputVariableFieldData(
  data: donkey.FieldType
): data is donkey.InputVariableFieldData {
  return isFieldType(data) && data.type === 'variable';
}

export function isBooleanVariableFieldData(
  data: donkey.FieldType
): data is donkey.BooleanVariableFieldData {
  return (
    isInputVariableFieldData(data) &&
    !!['can_', 'has_', 'is_', 'requires_'].find(
      (prefix) => data.ref.indexOf(prefix) === 0
    )
  );
}

export function isStringVariableFieldData(
  data: donkey.FieldType
): data is donkey.StringVariableFieldData {
  return isInputVariableFieldData(data) && !isBooleanVariableFieldData(data);
}

export function isImageBlock(block: donkey.DataBlock): boolean {
  return block.name === 'single_image';
}

export function isImagesBlock(block: donkey.DataBlock): boolean {
  return block.name === 'images';
}

export function isVideoBlock(block: donkey.DataBlock): boolean {
  return block.name === 'video';
}

export function isLinkBlock(block: donkey.DataBlock): boolean {
  return block.name === 'links';
}

export function supportsLinkStyling(block: donkey.DataBlock): boolean {
  return ['links', 'iframe', 'email_link'].includes(block.name);
}

export function blockHasOneLink(block: donkey.DataBlock): boolean {
  if (!isLinkBlock(block)) {
    return false;
  }

  return Object.keys(block.field_data.links).length === 1;
}

export function isSimpleBlock(block: donkey.DataBlock): boolean {
  return (
    isImageBlock(block) ||
    isImagesBlock(block) ||
    isVideoBlock(block) ||
    blockHasOneLink(block)
  );
}

export function blockHasPersonalizationVariables(
  block: donkey.DataBlock
): boolean {
  return Object.values(block.field_data).some(
    (field) =>
      isTextFieldData(field) && personalizationVariableRegExp.test(field.value)
  );
}

export function blockHasExPersonalizationVariables(
  block: donkey.DataBlock
): boolean {
  return Object.values(block.field_data).some(
    (field) =>
      isTextFieldData(field) && blockHasExLiquidVariables.test(field.value)
  );
}

export function blockHasPoll(block: donkey.DataBlock): boolean {
  const fields = Object.keys(block.field_data);
  return fields.some((name) => isPollFieldData(block.field_data[name]));
}

export function blockIsProcessing(block: donkey.DataBlock): boolean {
  const singleImageBlocks: string[] = [
    'single_image',
    'headline_popped',
    'author_image_header',
    'image_headlines',
    'text_over_image',
  ];

  if (
    singleImageBlocks.includes(block.name) &&
    isImageFieldData(block.field_data?.image)
  ) {
    return isImageProcessing(block.field_data.image);
  }
  if (
    block.name === 'images' &&
    isImageFieldDataArray(block.field_data?.images)
  ) {
    return isImageArrayProcessing(block.field_data.images);
  }
  if (
    block.name === 'links' &&
    isLinksFieldDataArray(block.field_data?.links)
  ) {
    return isLinksImageProcessing(block.field_data.links);
  }
  return false;
}

export function postHasPoll({
  blocks,
}: {
  blocks: donkey.DataBlock[];
}): boolean {
  return blocks.some(blockHasPoll);
}

export function urlsFromLinksBlock(
  block: donkey.DataBlock
): string[] | undefined {
  if (!supportsLinkStyling(block)) {
    return undefined;
  }

  const links = block.field_data.links as donkey.LinkFieldData[];

  return links.map(({ url }) => url);
}

export function urlFromSimpleLinkBlock(block: donkey.DataBlock): string | null {
  const urls = urlsFromLinksBlock(block);

  if (Array.isArray(urls)) {
    return urls[0];
  }

  return null;
}

function scrubField(
  field: donkey.Field,
  options?: {
    omit?: Array<
      | keyof donkey.VideoFieldData
      | keyof donkey.PollFieldData
      | 'poll_id'
      | keyof donkey.AttachmentFieldData
    >;
    override?: Partial<donkey.Field>;
  }
): donkey.Field {
  return {
    ...Object.fromEntries(
      Object.entries(field).filter(
        ([key, _]) => !options?.omit?.includes(key as keyof donkey.Field)
      )
    ),
    ...options?.override,
  } as donkey.Field;
}

// conditionally scrub a field's data
export function duplicateField(field: donkey.Field): donkey.Field {
  if (isPollFieldData(field)) {
    return scrubField(field, {
      omit: ['poll_id'],
      override: { processed: false },
    });
  }
  if (isVideoFieldData(field)) {
    return scrubField(field, {
      omit: [
        'video_id',
        'video_uuid',
        'video_length_sec',
        'sourceType',
        'custom_preview_image_url',
      ],
      override: {
        processed: false,
        preview_image_url: VIDEO.preview_image_url,
      },
    });
  }
  if (isAttachmentFieldData(field)) {
    return scrubField(field, {
      omit: ['status'],
      override: {
        file_url: 'https://example.com/example.pdf',
        name: 'Example PDF File',
        extension: 'pdf',
        size: 0,
        attachment_id: '0',
        attachment_uuid: '',
      },
    });
  }

  return field;
}

export function hiddenFields(
  blockFlags: string[] | undefined
): {
  hiddenTitle: boolean;
  hiddenDescription: boolean;
} {
  return {
    hiddenTitle: ['hidden-link-titles', 'hidden-headline'].some((item) =>
      blockFlags?.includes(item)
    ),
    hiddenDescription: [
      'hidden-description',
      'hidden-link-descriptions',
    ].some((item) => blockFlags?.includes(item)),
  };
}

import React, { useState } from 'react';
import {
  EmptyBanner,
  EmptyBannerDeleteItem,
} from 'shared/BannerListItem/EmptyBanner';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Topic } from 'models/topic';
import { pluralize } from 'utility/text';
import { ConfirmModal } from 'DesignSystem/Components';
import { useToggle } from 'hooks/useToggle';
import { TopicStatusPill } from './TopicStatusPill';
import styles from './topics.module.css';
import { topicToThumbnail, topicToMenuItems } from './mappings';
import { useActions } from './useActions';

type PropsType = {
  topic: Topic;
};

export const TopicBanner: React.FC<PropsType> = ({ topic }) => {
  const { id: programId } = useProgram();

  const topicLandingV2Enabled = useFeatureFlagsQuery(
    programId,
    'EE.TopicLanding.V2.ConfigEnabled'
  ).data?.value;

  const thumbnail = topicToThumbnail(topic);
  const actions = useActions(undefined, true);
  const adjustedActions = {
    ...actions,
    edit: (id: number) => {
      if (topicLandingV2Enabled && topic.archived) {
        setShowEditArchivedWarning(true);
      } else if (!topicLandingV2Enabled || topic.draft || topic.draftId) {
        actions.edit(id);
      } else {
        setShowDraftWarning(true);
      }
    },
    deleteDraft: () => {
      setDeleteDraftModalOpen();
    },
  };
  const menuItems = topicToMenuItems(programId, topic, adjustedActions, {
    detailsEnabled: !!topicLandingV2Enabled,
  });
  const [showDraftWarning, setShowDraftWarning] = useState(false);
  const [showEditArchivedWarning, setShowEditArchivedWarning] = useState(false);

  const {
    toggle: toggleDeleteDraftModal,
    value: deleteDraftModalOpen,
    enable: setDeleteDraftModalOpen,
  } = useToggle();

  const deleteDraft: EmptyBannerDeleteItem = {
    deleteAction: () => {
      actions.deleteDraft(topic);
    },
    showingDeleteModal: deleteDraftModalOpen,
    toggleDeleteModal: toggleDeleteDraftModal,
    resource: 'Draft Topic',
  };

  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      rowId={topic.id.toString()}
      deleteItem={deleteDraft}
    >
      <div className={styles.bannerWrapper}>
        <div className={styles.basic}>
          <div className={styles.title}>{topic.name}</div>
          <p className={styles.description} title={topic.description}>
            {topic.description}
          </p>
        </div>
        <div className={styles.statsWrapper}>
          {topicLandingV2Enabled && (
            <div className={`${styles.statCol} ${styles.statsStatus}`}>
              <span className={styles.statHeader}>STATUS</span>
              <span className={styles.stats}>
                <TopicStatusPill
                  topic={topic}
                  className={styles.statsStatusPill}
                />
              </span>
            </div>
          )}
          <div className={`${styles.statCol} ${styles.statsSettings}`}>
            <span className={styles.statHeader}>SETTINGS</span>
            {topic.contributorIds.length > 0 && (
              <span className={styles.stats}>
                {topic.contributorIds.length}{' '}
                {pluralize(topic.contributorIds.length, 'Contributor')}
              </span>
            )}
            {topic.autoPublish && (
              <span className={styles.stats}>Auto Publishing enabled</span>
            )}
            {topic.autoFollow && (
              <span className={styles.stats}>Auto Following enabled</span>
            )}
            {!topic.autoFollow &&
              !topic.autoPublish &&
              topic.contributorIds.length === 0 && (
                <span className={styles.stats}>None</span>
              )}
          </div>
          <div className={`${styles.statCol} ${styles.statsSubscribers}`}>
            <span className={styles.statHeader}>SUBSCRIBERS</span>
            <span className={styles.stats}>
              {topic.followerCount} {pluralize(topic.followerCount, 'User')}
            </span>
          </div>
        </div>
      </div>
      {showDraftWarning && (
        <ConfirmModal
          title="Creating a draft"
          confirmLabel="Continue"
          onConfirm={() => {
            actions.edit(Number(topic.id));
          }}
          onCancel={() => {
            setShowDraftWarning(false);
          }}
        >
          Editing this topic will create a draft. Any changes you make won’t
          affect the topic until you publish the changes.
        </ConfirmModal>
      )}
      {showEditArchivedWarning && (
        <ConfirmModal
          title="Cannot edit topic"
          confirmLabel="Unarchive and edit"
          onConfirm={() => {
            actions.unarchiveAndEdit(Number(topic.id));
          }}
          onCancel={() => {
            setShowEditArchivedWarning(false);
          }}
        >
          Archived topics cannot be edited. To edit, first unarchive the topic.
          Note that when you unarchive a topic, &quot;Show in Experience&quot;
          will be set to Off. To turn visibility On, change the setting in the
          Settings tab.
        </ConfirmModal>
      )}
    </EmptyBanner>
  );
};

import React, { useMemo } from 'react';
import { useLibraryFontsCollection } from 'hooks/use-library-fonts-collection';
import { DesignContext, DesignError, useDesignContext } from 'contexts/design';
import { useDesignHooks } from 'components/publisher/theme/Design/useDesignHooks';
import { PublisherType } from 'models/library';
import { DesignData } from 'services/api-design';
import { DesignComponent } from 'components/publisher/theme/Design/Component';
import { Design } from 'components/publisher/pages/Design';
import { useJourneyState } from 'contexts/journey';
import { usePortal } from 'hooks/usePortal';
import { createPortal } from 'react-dom';
import { useProgram } from 'contexts/program';
import { useDesign } from 'hooks/design';
import { NavigationBars } from './NavigationBars';
import { useJourneyContentDesigner } from './JourneyContentDesignProvider';
import styles from './styles.module.css';
import { ContentListState } from '../JourneyContentListDrawer/Drawer';
import { JourneyModal } from '../JourneyModal';
import { CommunicationPreview } from '../JourneyDrawer/CommunicationPreview';

export const JourneyContentDesignerComponent: React.FC = () => {
  const portal = usePortal('journey-content-designer');
  const { data: fonts } = useLibraryFontsCollection();
  const {
    isDesignerOpen,
    closeDesigner,
    onSelectDesign,
  } = useJourneyContentDesigner();
  const { activeStep, setContentListState } = useJourneyState();
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  const fontOptions = useMemo(() => {
    return fonts
      .filter((i) => i.is_enabled_for_program)
      .map((i) => {
        return {
          label: i.title,
          value: i.asset.value,
          id: i.id,
          url: i.asset.css?.url,
          is_enabled_for_program: i.is_enabled_for_program,
        };
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [fonts]);

  const designContext = useDesignContext();
  const designEditor = useDesignHooks({
    design: designContext.design,
    fontOptions,
    update: designContext.update,
    publisherType: PublisherType.topicPages,
  });

  React.useEffect(() => {
    if (activeStep?.id) {
      designContext.update({
        ...designContext.design,
        parentId: activeStep?.id,
        parentSource: 'hermes',
        parentType: 'CommunicationNode',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep?.id]);

  const onSaveSuccess = React.useCallback(
    (design?: DesignData) => {
      if (design?.attributes) {
        onSelectDesign({ ...design.attributes }, true);
        closeDesigner();
        setContentListState(ContentListState.Closed);
      }
    },
    [closeDesigner, onSelectDesign, setContentListState]
  );

  const onSaveError = React.useCallback((errors: Array<DesignError>) => {
    const hasNameError = errors.some((e: { detail?: string }) =>
      e.detail?.includes('Name has already been taken')
    );
    setIsErrorModalOpen(true);
    if (errors && hasNameError) {
      setErrorMessage(
        'The name of this content is already in use by another piece of content. Change the name of this content and try saving again.'
      );
    } else {
      setErrorMessage(
        'There was an issue saving this content. Please try again.'
      );
    }
  }, []);

  const saveAndClose = React.useCallback(() => {
    designContext.save({ onSuccess: onSaveSuccess, onError: onSaveError });
  }, [designContext, onSaveError, onSaveSuccess]);

  const navigationComponent = (
    <NavigationBars
      isErrorModalOpen={isErrorModalOpen}
      setIsErrorModalOpen={setIsErrorModalOpen}
      actionName="Save"
      canPerformAction
      exitHandler={closeDesigner}
      action={saveAndClose}
      setErrorMessage={setErrorMessage}
    />
  );
  const dismissErrorModal = React.useCallback(() => {
    setIsErrorModalOpen(false);
    setErrorMessage(undefined);
  }, [setIsErrorModalOpen]);

  if (
    !isDesignerOpen ||
    !activeStep ||
    activeStep?.type !== 'communication' ||
    !portal.ready
  )
    return null;

  return createPortal(
    <div className={styles.journeyDesignEditorModal}>
      <DesignComponent
        path="/"
        designPermission={{ canEdit: true, errors: [], isLoading: false }}
        designEditor={designEditor}
        navigationComponent={navigationComponent}
        omitMenuItems={['card', 'personalizedFields']}
      >
        {designEditor?.previewEnabled && (
          <div className={styles.preview}>
            <CommunicationPreview
              design={designContext?.design}
              step={activeStep}
            />
          </div>
        )}
        {isErrorModalOpen && errorMessage && !designEditor?.previewEnabled && (
          <JourneyModal
            name="Save error"
            header="Save error"
            body={errorMessage}
            action={dismissErrorModal}
            actionLabel="OK"
          />
        )}
        {!designEditor?.previewEnabled && <Design implementation="new" />}
      </DesignComponent>
    </div>,
    portal.target
  );
};

export const JourneyContentDesigner: React.FC = () => {
  const { id: programId } = useProgram();
  const { activeStep } = useJourneyState();
  const { designerId: designId } = useJourneyContentDesigner();
  const designContext = useDesign(programId, designId || 'new', false);
  if (!activeStep || activeStep.type !== 'communication') return null;

  return (
    <DesignContext.Provider value={designContext}>
      {designContext.status.hasLoaded && <JourneyContentDesignerComponent />}
    </DesignContext.Provider>
  );
};

import React from 'react';
import { useMutation, useQueries, useQuery, UseQueryResult } from 'react-query';
import { addVideo, fetchVideo, UploadVideoProps } from 'services/api-assets';
import { CaptionLocaleUrl, Video } from 'models/video';
import {
  CaptionsJobs,
  fetchCaptionsJobs,
  fetchTranslationLanguages,
  TranslationLanguage,
} from 'services/api-captions';
import { MutationOptions, MutationResponse, QueryResponse } from './common';

export const useVideoQuery = (props: {
  programId: number;
  isDesignAsset?: boolean;
  videoId?: number;
  enabled?: boolean;
  refetchInterval?: number;
}): QueryResponse<Video | undefined> & {
  refetch: ReturnType<typeof useQuery>['refetch'];
} => {
  const { programId, isDesignAsset, videoId, enabled, refetchInterval } = props;
  const { isLoading, error, data, refetch } = useQuery<
    Video | undefined,
    Error
  >(
    ['video', programId, videoId, isDesignAsset],
    () => fetchVideo({ programId, isDesignAsset, videoId }),
    {
      enabled,
      refetchInterval,
    }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
    refetch,
  };
};

export const useCaptionsJobsQuery = (props: {
  programId: number;
  videoId?: number;
  enabled?: boolean;
  isDesignAsset?: boolean;
  refetchInterval?: number;
}): QueryResponse<CaptionsJobs> & {
  refetch: ReturnType<typeof useQuery>['refetch'];
} => {
  const { programId, videoId, isDesignAsset, enabled, refetchInterval } = props;
  const queryKey = 'captions-jobs';
  const { isLoading, error, data, refetch } = useQuery<CaptionsJobs, Error>(
    [queryKey, programId, videoId],
    () => fetchCaptionsJobs({ programId, isDesignAsset, videoId }),
    {
      enabled: enabled && videoId !== undefined,
      refetchInterval,
    }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data,
    refetch,
  };
};

export const useCaptionsLanguagesQuery = (props: {
  programId: number;
  enabled?: boolean;
}): QueryResponse<Array<TranslationLanguage>> => {
  const { programId, enabled } = props;
  const { isLoading, error, data } = useQuery<
    Array<TranslationLanguage>,
    Error
  >(
    ['translation-languages', programId],
    () => fetchTranslationLanguages({ programId }),
    {
      enabled,
    }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useVideoUpload = ({
  onSuccess,
}: MutationOptions<number> = {}): MutationResponse<UploadVideoProps> => {
  const { mutate, isLoading, error } = useMutation<
    number,
    Error,
    UploadVideoProps
  >(addVideo, { onSuccess });

  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

export type FetchedCaption = CaptionLocaleUrl & {
  id: number;
  text: string;
  generated: boolean;
};

export function useCaptions(
  video?: Video
): UseQueryResult<FetchedCaption, Error>[] {
  const queries = React.useMemo(
    () =>
      (video?.captions ?? []).map(({ id, locale, url, generated }) => {
        return {
          queryKey: url,
          queryFn: () =>
            url &&
            fetch(url, {
              credentials: 'include',
            }).then(async (response) => ({
              id,
              language: locale,
              url,
              generated,
              text: response.ok ? await response.text() : '',
            })),
        };
      }),
    [video?.captions]
  );
  return useQueries(queries) as UseQueryResult<FetchedCaption, Error>[];
}

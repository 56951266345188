import React from 'react';
import { Button } from 'DesignSystem/Form';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import styles from './journey-drawer.module.css';

interface IJourneyDrawerHeader {
  title: string;
  description: React.ReactNode | string;
  icon: React.ReactNode;
  actions?: JourneyDrawerHeaderAction[];
}

export type JourneyDrawerHeaderAction = {
  label: string;
  onClick: () => void;
  secondary?: boolean;
  icon?: React.ReactNode;
};

const JourneyDrawerHeader: React.FC<IJourneyDrawerHeader> = ({
  children,
  title,
  description,
  icon,
  actions = [],
}) => {
  return (
    <div>
      <Flex start>
        <div className={styles.journeyDrawerContentIcon}>{icon}</div>
        <FlexItem column widen alignStart>
          <h2 className={styles.journeyDrawerContentTitle}>{title}</h2>
          <p className={styles.journeyDrawerContentSubtitle}>{description}</p>
        </FlexItem>
        <Flex className={styles.journeyDrawerContentActions}>
          {actions.map((action) => (
            <Button
              compact
              secondary={action.secondary}
              key={action.label}
              onClick={action.onClick}
              label={action.label}
              icon={action.icon}
            />
          ))}
        </Flex>
      </Flex>

      <div>{children}</div>
    </div>
  );
};

export default JourneyDrawerHeader;

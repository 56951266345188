import { useProgramIdState } from 'contexts/program';
import { VideoFieldData } from 'models/donkey';
import { videoToField } from 'models/publisher/block';
import { useCallback, useState } from 'react';
import { useDesignContext } from 'contexts/design';
import { uploadVideoPreviewUrl } from 'services/api-assets';
import { IMAGE_ACCEPT_STRING, ImageData } from 'models/image';
import { Video } from 'models/video';
import { useImageUploader } from 'hooks/useImageUploader';
import { FieldFormProps } from '../../../useFieldForm';
import { useFetchVideoFromFeed } from './useFetchVideoFromFeed';
import * as SourceMenu from './useVideoSourceMenu';
import { useKeepOpen } from '../../shared/KeepOpen';
import { ExitBlocker } from '../../Image/components/ExitBlocker';

export const useVideoPreviewImage: ({
  onChange,
  fieldData,
  video,
  refetchVideo,
}: {
  onChange: FieldFormProps<VideoFieldData>['onChange'];
  fieldData: VideoFieldData;
  video?: Video;
  refetchVideo: ReturnType<typeof useFetchVideoFromFeed>['refetchVideo'];
}) => {
  isUploading: boolean;
  image: ImageData;
  errorMessage?: string;
  removeCustomPreviewImage: () => void;
} & ReturnType<typeof SourceMenu.useVideoSourceMenu> = ({
  onChange,
  fieldData,
  video,
  refetchVideo,
}) => {
  const [programId] = useProgramIdState();
  const { active: isDesignAsset, parentType } = useDesignContext();

  const persistPreviewImage = useCallback(
    async (image: Pick<ImageData, 'url'>) => {
      if (!video) return { data: undefined };

      await uploadVideoPreviewUrl({
        programId,
        isDesignAsset,
        parentType,
        videoId: video.id,
        url: image.url,
      });
      return refetchVideo();
    },
    [isDesignAsset, parentType, programId, refetchVideo, video]
  );

  const removeCustomPreviewImage = useCallback(async () => {
    if (!video) return;

    setIsUploading(true);

    // When removing the custom preview image, replace it
    // with the generated thumbnail.
    const image = { url: video.generatedThumbnail || '' };
    const { data } = await persistPreviewImage(image);
    if (data) {
      onChange(
        videoToField({
          ...video,
          customPreviewImageUrl: undefined,
          previewImageUrl: data.previewImageUrl,
        })
      );
    }

    setIsUploading(false);
  }, [onChange, persistPreviewImage, video]);

  const {
    image: imageFromUploader,
    isUploading: uploaderIsUploading,
    update,
  } = useImageUploader({
    onUpload: async (image) => {
      const { data } = await persistPreviewImage(image);
      if (!data || !video) return;

      onChange(
        videoToField({
          ...video,
          customPreviewImageUrl: image.url,
          previewImageUrl: data.previewImageUrl,
        })
      );
    },
    programId,
    onError: (error) => {
      setIsUploading(false);
      setErrorMessage(error);
    },
  });

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isUploading, setIsUploading] = useState(uploaderIsUploading);

  useKeepOpen(ExitBlocker, isUploading);

  const onUpload = useCallback(
    (image: File | string) => {
      setIsUploading(true);
      setErrorMessage(undefined);
      update(image);
    },
    [update]
  );

  const previewImageUrlFromVideo =
    fieldData.custom_preview_image_url || fieldData.preview_image_url;
  const [previewImageUrl, setPreviewImageUrl] = useState<string>(
    previewImageUrlFromVideo
  );
  if (previewImageUrlFromVideo !== previewImageUrl) {
    setIsUploading(false);
    setPreviewImageUrl(previewImageUrlFromVideo);
  }

  const {
    fileInput,
    libraryInput,
    externalInput,
    ...source
  } = SourceMenu.useVideoSourceMenu({
    onChange: onUpload,
    inputFileAccept: IMAGE_ACCEPT_STRING,
    menuSources: [SourceMenu.UPLOAD, SourceMenu.LIBRARY, SourceMenu.EXTERNAL],
    externalSourceType: SourceMenu.ExternalSourceType.Image,
  });

  return {
    isUploading,
    image: imageFromUploader,
    errorMessage,
    fileInput,
    libraryInput,
    externalInput,
    removeCustomPreviewImage,
    ...source,
  };
};

import { DateTime } from 'luxon';
import { Duration, DurationOptions } from 'models/duration';
import cx from 'classnames';
import React, { useState } from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import { DurationSelector } from 'shared/DurationInput/DurationSelector';
import { capitalizeFirstLetter } from 'utility/strings';
import { selectComponents } from '../Select';
import styles from './DurationSelect.module.css';

function parseValue(val?: string) {
  if (!val) {
    return {
      label: 'x',
      value: 1,
      unit: DurationOptions[0],
    };
  }

  const [x, unit] = val.split(' ');
  const unitObj = DurationOptions.find((u) => u.value === unit);

  return {
    label: 'x',
    value: +x || 1,
    unit: unitObj || DurationOptions[0],
  };
}

export const DurationSelect: React.FC<{
  onChange: (value?: string) => void;
  value?: string;
  futureDuration?: boolean;
  ignoreYearFlag: boolean;
  ignoreYear: boolean;
  toggleIgnoreYear: () => void;
  newDateOperatorsEnabled?: boolean;
  operator?: string;
  isDisabled?: boolean;
}> = ({
  onChange,
  value,
  futureDuration = false,
  ignoreYearFlag = false,
  ignoreYear = false,
  toggleIgnoreYear = () => {},
  newDateOperatorsEnabled = false,
  operator,
  isDisabled,
}) => {
  const initialValue = parseValue(value);
  const [durationValue, setDurationValue] = useState<Duration>(initialValue);
  const [isSelectorOpen, setSelectorState] = useState<boolean>(false);
  const durationToQuery = (e: Duration) => {
    const un = DurationOptions.find((u) => u.value === e.unit?.value);

    return {
      value: `${e.value} ${un?.value}`,
      label: `${e.value} ${un?.value}`,
    };
  };

  const handleDurationChange = (e: Duration) => {
    const duration = durationToQuery(e);
    onChange(duration?.value);
    setDurationValue(e);
  };

  let startDate = DateTime.now();

  if (!futureDuration) {
    const numberOfUnits = durationValue.value || 1;
    const endDate = DateTime.now();
    switch (durationValue.unit?.value) {
      case 'week':
        startDate = endDate.minus({ days: numberOfUnits * 7 });
        break;
      case 'month':
        startDate = endDate.minus({ months: numberOfUnits });
        break;
      case 'quarter':
        startDate = endDate.minus({ months: numberOfUnits * 3 });
        break;
      case 'year':
        startDate = endDate.minus({ years: numberOfUnits });
        break;
      default:
        startDate = endDate.minus({ days: numberOfUnits });
    }
  }

  const dropdownRenderProp = () => (
    <DurationSelector
      onDurationChange={handleDurationChange}
      value={durationValue}
      startDate={startDate}
      ignoreYearFlag={ignoreYearFlag}
      ignoreYear={ignoreYear}
      toggleIgnoreYear={toggleIgnoreYear}
      operator={operator}
      newDateOperatorsEnabled={newDateOperatorsEnabled}
    />
  );

  const processDurationValue = (d: Duration) => {
    if (!newDateOperatorsEnabled) return d.unit?.value;
    if (d.value === 0 || d.value > 1) {
      return `${capitalizeFirstLetter(d.unit?.value)}s`;
    }
    return `${capitalizeFirstLetter(d.unit?.value)}`;
  };

  return (
    <div
      className={cx(styles.durationInput, {
        [styles.disabledInput]: isDisabled,
      })}
    >
      <ClickDropdown
        dropdownRenderProp={dropdownRenderProp}
        onOpen={() => setSelectorState(true)}
        onClose={() => setSelectorState(false)}
        isOpen={isSelectorOpen}
        id="durationSelectElement"
        disabled={isDisabled}
      >
        <span className={styles.dropdownIndicator}>
          <selectComponents.DropdownIndicator />
        </span>
        <button type="button" className={styles.btnSelect}>
          <span className={styles.label}>
            {`${durationValue.value} ${processDurationValue(durationValue)}`}
          </span>
        </button>
      </ClickDropdown>
    </div>
  );
};

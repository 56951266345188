import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Caption, FieldLabel } from 'DesignSystem/Typography';
import * as FlexLayout from 'DesignSystem/Layout/Flex';
import { EmailSenderAlias } from 'models/publisher/settings';
import {
  CommunicationStep,
  DeliveryChannel,
  DeliveryChannels,
  EmailChannel,
} from 'models/journeys/journey';
import { isCommunicationStepError } from 'models/journeys/journey-errors';
import { useDefaultEmailAddress } from 'hooks/email-alias';
import { ChannelSelection } from 'components/channel-selection/channel-selection';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { useChannelSelectionState } from 'components/channel-selection/hooks';
import { Checkbox } from 'DesignSystem/Form';
import { useJourneyState } from 'contexts/journey';
import { Icon } from '@socialchorus/shared-ui-components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useJourneyValidationErrors } from '../../JourneyErrors/useJourneyValidationErrors';
import { NotificationCard } from '../../../../Editors/Publisher/Deliver/Notifications/NotificationCard';
import { useJourneyContentDesigner } from '../../JourneyContentDesigner/JourneyContentDesignProvider';
import styles from './communication-config.module.css';
import { EmailConfig } from './channel-configs';

export type DeliveryChannelOption = {
  id?: string;
  name: keyof DeliveryChannels;
  label: string;
};

function isEmailChannel(channel: DeliveryChannel): channel is EmailChannel {
  return channel.name === 'email';
}

const defaultEmailConfig: EmailChannel = {
  name: 'email',
  previewText: '',
  subject: '',
};

export const ReadOnlyCommunicationConfig: React.FC<{
  step: CommunicationStep;
}> = ({ step }) => {
  const emailChannel = step.channels.find(isEmailChannel);
  return (
    <FlexLayout.Flex spread>
      <Box width="50%">
        {emailChannel && (
          <Box>
            <Box>
              <FieldLabel>Email Alias</FieldLabel>
              <Box margin={[8, 0, 0, 0]}>
                {emailChannel.emailSenderAlias?.senderEmail}
              </Box>
            </Box>
            <Box margin={[24, 0, 0, 0]}>
              <FieldLabel>Subject and Preview Text</FieldLabel>
              <NotificationCard
                notification={{
                  text: emailChannel.subject,
                  previewText: emailChannel.previewText,
                  order: 0,
                }}
                disablePush
              />
            </Box>
          </Box>
        )}
      </Box>
    </FlexLayout.Flex>
  );
};

export const CommunicationConfig: React.FC<{
  step: CommunicationStep;
  onUpdateStep: (step: CommunicationStep) => void;
}> = ({ step, onUpdateStep }) => {
  const { errorsForStep, resolveErrors } = useJourneyValidationErrors();
  const { inlineEditingEnabled } = useJourneyContentDesigner();
  const stepErrors = errorsForStep(step.id);
  const errors = isCommunicationStepError(stepErrors) ? stepErrors : undefined;
  const {
    approveCommunicationStep,
    removeCommunicationStepApproval,
  } = useJourneyState();

  const handleEmailAliasChange = (emailSenderAlias: EmailSenderAlias) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((c) =>
      c.name === 'email'
        ? {
            ...c,
            emailSenderAlias,
            programContactAddressId: parseInt(emailSenderAlias.id, 10),
          }
        : c
    );

    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
    resolveErrors(step.id, ['emailChannelAddress']);
  };

  const handleSubjectChange = (subject: string) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((c) =>
      c.name === 'email' ? { ...c, subject } : c
    );
    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
    resolveErrors(step.id, ['emailChannelSubject']);
  };

  const handlePreviewTextChange = (previewText: string) => {
    const updatedChannels: DeliveryChannel[] = step.channels.map((c) =>
      c.name === 'email' ? { ...c, previewText } : c
    );
    onUpdateStep({
      ...step,
      channels: updatedChannels,
    });
    resolveErrors(step.id, ['emailChannelPreview']);
  };

  const approve = () => {
    approveCommunicationStep(step.id);
    resolveErrors(step.id, ['approved']);
  };

  const removeApproval = () => {
    removeCommunicationStepApproval(step.id);
  };

  const { data: defaultAddress } = useDefaultEmailAddress();
  const emailChannelConfig = step.channels.find(isEmailChannel);
  const emailChannel = emailChannelConfig ?? defaultEmailConfig;
  const { checked, toggleChecked } = useChannelSelectionState({
    initialCheckedChannels: {
      email: Boolean(emailChannelConfig),
      notificationCenter: true,
      push: true,
    },
  });

  const notificationCenterIsEnabled = useNotificationCenterEnabled();

  const emailChannelSubject =
    emailChannel.subject === 'Default' || !emailChannel.subject
      ? ''
      : emailChannel.subject;
  const emailChannelPreviewText =
    emailChannel.previewText === 'Default' || !emailChannel.previewText
      ? ''
      : emailChannel.previewText;
  const emailChannelSenderAlias =
    emailChannel.emailSenderAlias || defaultAddress;

  return (
    <FlexLayout.Flex spread>
      <Box margin={[16, 0, 0, 0]} width="720px">
        {inlineEditingEnabled && (
          <Box>
            <FieldLabel>Content Approval</FieldLabel>
            <Box padding={[24, 0]}>
              <Checkbox
                labelClassName={styles.communicationApprovalLabel}
                onSelect={approve}
                onDeselect={removeApproval}
                label={<FieldLabel>Ready for Publish</FieldLabel>}
                checked={step.approved || false}
              />
              {errors?.approved && (
                <Flex start className={styles.communicationApprovalWarning}>
                  <Icon size={16}>warning</Icon>
                  <Caption>
                    This communication must be marked Ready to publish this
                    journey
                  </Caption>
                </Flex>
              )}
            </Box>
          </Box>
        )}
        {(() => {
          if (notificationCenterIsEnabled) {
            return (
              <ChannelSelection checked={checked} toggleChecked={toggleChecked}>
                <ChannelSelection.Email
                  emailAlias={emailChannelSenderAlias}
                  onEmailAliasChange={handleEmailAliasChange}
                  subject={emailChannelSubject}
                  onSubjectChange={handleSubjectChange}
                  previewText={emailChannelPreviewText}
                  onPreviewTextChange={handlePreviewTextChange}
                  errors={
                    errors && {
                      emailAlias: errors.emailChannelAddress || [],
                      subject: errors.emailChannelSubject || [],
                      previewText: errors.emailChannelPreview || [],
                    }
                  }
                />

                <ChannelSelection.NotificationCenter
                  title=""
                  onTitleChange={() => {}}
                  pushMessage=""
                  onPushMessageChange={() => {}}
                  markedAsImportant={false}
                  onMarkedAsImportantChange={() => {}}
                />
              </ChannelSelection>
            );
          }

          return (
            <EmailConfig
              errors={errors}
              disabled={false}
              onEmailAliasChange={handleEmailAliasChange}
              emailSenderAlias={emailChannelSenderAlias}
              subject={emailChannelSubject}
              onSubjectChange={handleSubjectChange}
              previewText={emailChannelPreviewText}
              onPreviewTextChange={handlePreviewTextChange}
            />
          );
        })()}
      </Box>
    </FlexLayout.Flex>
  );
};

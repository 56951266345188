import { createContext, useContext } from 'react';

import { DateTime } from 'luxon';
import { Design } from 'models/design';

export type DesignError = { detail: string };
type DesignData = {
  attributes: Design;
};

export const defaultDesign: Design = {
  id: 'new',
  name: '',
  blocks: [],
  meta: {},
};

export type DesignStatus = {
  isSaving: boolean;
  isLoading: boolean;
  hasLoaded: boolean;
  lastModified?: DateTime;
  lastSaveSuccess?: DateTime;
  error?: 'unauthorized' | 'not-found';
};

export type usePersistDesign = {
  save: (
    options?: Partial<{
      design: Design;
      onSuccess: (design?: DesignData) => void;
      onError: (errors: Array<DesignError>) => void;
      callback: (design?: Design) => void;
    }>
  ) => void;
  status: DesignStatus;
};

export type UseDesign = {
  id: number | 'new';
  design: Design;
  update: (changes: Design) => void;
  save: usePersistDesign['save'];
  error?: string;
  isProcessing: boolean;
  status: DesignStatus;
  active: boolean;
  parentType?: string;
};

export const defaultDesignStatus: DesignStatus = {
  isSaving: false,
  isLoading: false,
  hasLoaded: false,
};

const contextPrototype: UseDesign = {
  id: 'new',
  design: { ...defaultDesign },
  update: () => {},
  save: () => {},
  status: defaultDesignStatus,
  isProcessing: false,
  active: false,
};

export const DesignContext = createContext(contextPrototype);

export const useDesignContext = (): UseDesign => {
  const context = useContext(DesignContext);

  if (context === undefined) {
    throw new Error(
      'Design context hooks require a containing Design Provider'
    );
  }

  return context;
};

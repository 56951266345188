import { Post, updateCallToAction } from 'models/publisher/post';
import * as donkey from 'models/donkey';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import { DateTime } from 'luxon';
import {
  ACKNOWLEDGEMENT_LABELS,
  defaultSettings,
  FEATURED_LABELS,
  Label,
  Settings,
} from 'models/publisher/settings';

import { BASE_STYLING } from 'models/publisher/style';
import { TOPIC } from 'models/topic';
import { audiencesToCriterion } from 'models/publisher/criterion';
import {
  AttachmentFieldData,
  definitionToData,
  RenderingContext,
  RenderingVariables,
} from 'models/publisher/block';
import { CallToAction } from 'models/publisher/call-to-action';
import { Author } from 'models/author';
import { datadogRum } from '@datadog/browser-rum';
import { postHeaders } from 'utility/post-headers';
import { ValidationError } from './Errors/ValidationError';
import { bossanovaDomain, deepCamelcaseKeys, request } from './api-shared';
import { Attributes, ServerPost } from './server-post-type';
import { resolveBlocks } from './api-content-blocks';
import {
  deserializeChannels,
  deserializeDeliveryType,
  deserializeDuration,
  deserializeNotifications,
  deserializePriority,
  serializeChannels,
  serializeDeliveryType,
  serializeDuration,
  serializeIncludeInForYou,
  serializeNotifications,
  serializePriority,
} from './serializers';

export type UpsertType = {
  programId: number;
  post: Post;
  variables: RenderingVariables;
};

type PostRequestType = {
  programId: number;
  postId: number;
};

function extractContentCommunicationAttributes(
  settings: Settings,
  publishedAt?: DateTime
) {
  return {
    criterion: audiencesToCriterion(settings.audiences),
    audiences: settings.audiences,
    priority: serializePriority(settings.priority),
    channels: serializeChannels(settings.deliveryChannels),
    delivery_type: serializeDeliveryType(
      settings.audiences,
      settings.deliveryType
    ),
    optimized_delivery_enabled: settings.optimizedDeliveryEnabled,
    retries: settings.retries,
    duration: serializeDuration(settings?.duration),
    program_contact_address_id: settings.emailSenderAlias?.id,
    notifications: serializeNotifications(
      settings.notifications,
      settings.retries,
      settings.notifDeliveryTimesEnabled,
      publishedAt
    ),
    notif_delivery_times_enabled: settings.notifDeliveryTimesEnabled,
    include_in_for_you: serializeIncludeInForYou(settings),
    limit_visibility_to_audience: settings.limitVisibilityToAudience,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function transformPostResponse(data: any): ServerPost {
  return {
    ...data,
    attributes: camelcaseKeys(data.attributes, {
      deep: true,
      stopPaths: ['content_block.processed.blocks'],
    }),
  };
}

function cleanupPostSettings(post: Post) {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    overrideIntelligence,
    priority,
    deliveryChannels,
    archiveAt,
    deliveryType,
    retries,
    duration,
    notifications,
    publishedAt,
    shareMessage,
    emailSenderAlias,
    contentTopics,
    acknowledge,
    acknowledgementLabel,
    audiences,
    limitVisibilityToAudience,
    contentLabel,
    notifDeliveryTimesEnabled,
    includeInForYou,
    optimizedDeliveryEnabled,
    deliveryPageVersion,
    customSlugs,
    ...settings
  } = post.settings;

  return {
    ...settings,
    content_communication: extractContentCommunicationAttributes(
      post.settings,
      publishedAt
    ),
    published_at: publishedAt ? publishedAt.toISO() : null,

    prepopulated_share_message: shareMessage,
    publication_state: post.content.publicationState,
    content_acknowledgement: acknowledge
      ? {
          label: acknowledgementLabel?.preset ?? null,
          custom_label: acknowledgementLabel?.text ?? null,
        }
      : null,
    content_label: contentLabel?.preset ?? contentLabel?.text ?? null,
    content_custom_slug_ids: (customSlugs || []).map((slug) => Number(slug.id)),
  };
}

function extractAcknowledgment(
  attributes: Attributes
): {
  acknowledge: boolean;
  acknowledgementLabel?: Label;
} {
  if (!attributes.contentAcknowledgement) return { acknowledge: false };

  const preset = attributes.contentAcknowledgement.label;

  if (preset) {
    const found = ACKNOWLEDGEMENT_LABELS.find((l) => l.preset === preset);
    if (!found)
      throw new Error(`Received preset #{preset}, but have no label for it`);
    return { acknowledge: true, acknowledgementLabel: found };
  }

  return {
    acknowledge: true,
    acknowledgementLabel: {
      text: attributes.contentAcknowledgement.customLabel,
    },
  };
}

function extractContentLabel(attributes: Attributes): Label | undefined {
  if (!attributes.isFeatured) return undefined;

  if (!attributes.contentLabel)
    return FEATURED_LABELS.find((l) => l.preset === 'default');

  const { preset } = attributes.contentLabel;

  if (preset) {
    const found = FEATURED_LABELS.find((l) => l.preset === preset);
    if (!found)
      throw new Error(`Received preset #{preset}, but have no label for it`);
    return found;
  }

  return attributes.contentLabel;
}

function extractCallToAction(attributes: Attributes): CallToAction {
  const image = attributes.contentCover?.image
    ? {
        ...attributes.contentCover.image,
        imageId: `${attributes.contentCover.image.id}`,
        processed: attributes.contentCover.image.status === 'completed',
      }
    : undefined;

  return {
    title: attributes.title,
    summary: attributes.summary,
    image,
    useTextFromContent: attributes.contentCover?.useTextFromContent ?? true,
    preferCustom: attributes.contentCover?.preferCustom ?? true,
    customAvailable: false,
    customEnabled: false,
    altText: attributes.contentCover?.altText ?? '',
  };
}

export async function mapServerDataToPost(
  programId: number,
  data: ServerPost,
  variables?: donkey.RenderingVariables
): Promise<Post> {
  const { attributes } = data;
  const cc = attributes.contentCommunication;

  const duration = deserializeDuration(cc?.duration);
  const notifications = deserializeNotifications(cc?.notifications);
  const deliveryChannels = deserializeChannels(cc?.channels ?? []);
  const priority = deserializePriority(cc?.priority);
  const deliveryType = deserializeDeliveryType(cc?.deliveryType);
  const optimizedDeliveryEnabled = cc?.optimizedDeliveryEnabled;

  return updateCallToAction(
    {
      content: {
        id: attributes.id,
        publicationState: attributes.publicationState,
        processingState: attributes.processingState,
        title: attributes.title,
        type: 'content_planner',
        contentAuthor: attributes.contentAuthor,
        contentTopics: attributes.contentChannels,
        permalinkUrl: attributes.permalinkUrl,
        meta: attributes?.contentBlock?.processed?.meta || {},
        libraryTemplateId: attributes.libraryTemplateId,
        contentNote: attributes.contentNote,
        createdBy: attributes.createdBy,
        isHidden: attributes.isHidden,
        readTimeInSeconds: attributes.readTimeInSeconds,
        wordCount: attributes.wordCount,
        version: attributes.version,
        publishedAt: attributes.publishedAt,
        createdAt: attributes.createdAt,
        updatedAt: attributes.updatedAt,
        contentCommunication: attributes.contentCommunication,
        hasPfFile: attributes.hasPfFile,
      },
      blocks: await resolveBlocks(
        programId,
        attributes?.contentBlock?.processed?.blocks || []
      ),
      callToAction: extractCallToAction(attributes),
      settings: {
        priority,
        publishedAt: attributes.publishedAt
          ? DateTime.fromISO(attributes.publishedAt)
          : undefined,
        duration,
        notifications,
        deliveryType,
        retries: cc?.retries,
        isCommentable: attributes.isCommentable,
        isTranslatable: attributes.isTranslatable,
        isFeatured: attributes.isFeatured,
        isShareable: attributes.isShareable,
        archiveAt: attributes.expiredAt
          ? DateTime.fromISO(attributes.expiredAt)
          : undefined,
        isResource: attributes.isResource,
        ...extractAcknowledgment(attributes),
        shareMessage: attributes.prepopulatedShareMessage,
        slug: attributes.publicContent
          ? attributes.publicContent.slug
          : defaultSettings.slug,
        contentLabel: extractContentLabel(attributes),
        initiativeTags: attributes.initiativeTags,
        searchMetaTags: attributes.searchMetaTags,
        contentAuthor: attributes.contentAuthor,
        contentTopics: attributes.contentChannels?.map((channel) => ({
          ...TOPIC,
          ...channel,
        })),
        audiences: cc?.audiences ?? [],
        audiencesCopy: cc?.audiencesCopy ?? [],
        limitVisibilityToAudience: cc?.limitVisibilityToAudience ?? false,
        deliveryChannels,
        emailSenderAlias: attributes.emailSenderAlias,
        overrideIntelligence: false,
        notifDeliveryTimesEnabled: !!attributes.contentCommunication
          ?.notifDeliveryTimesEnabled,
        publicationState: attributes.publicationState,
        displaySettings: attributes.displaySettings,
        includeInForYou: cc?.includeInForYou,
        deliveryPageVersion: attributes.deliveryPageVersion,
        optimizedDeliveryEnabled,
        customSlugs: attributes.customSlugs,
      },
      styles: attributes?.contentBlock?.raw?.styles ?? BASE_STYLING,
      meta: attributes.meta,
    },
    variables
  );
}

export const getPost = async (props: PostRequestType): Promise<ServerPost> => {
  const url = `${bossanovaDomain}/samba/programs/${props.programId}/contents/${props.postId}`;
  const response = await request(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 200) {
    return transformPostResponse(await response.json());
  }

  throw new Error(`Error fetching content: ${response.status}`);
};

function coverAttributes(post: Post) {
  const { callToAction } = post;

  return {
    title: callToAction.title,
    summary: callToAction.summary,
    content_cover: {
      use_text_from_content: callToAction.useTextFromContent,
      prefer_custom: callToAction.preferCustom,
      alt_text: callToAction.altText,
      image: callToAction.image,
    },
  };
}

function extractAttachmentInfo(post: Post) {
  return post.blocks
    .filter((block) => block.name === 'attachment')
    .map(definitionToData)
    .map((attachment) => ({
      id: Number(
        (attachment.field_data.attachment as AttachmentFieldData).attachment_id
      ),
      name: (attachment.field_data.attachment as AttachmentFieldData).name,
    }));
}

function upsertBody(
  post: Post,
  programId: number,
  variables: RenderingVariables
) {
  if (!post.styles) throw new Error('no post style!');

  const settings = cleanupPostSettings(post);

  return {
    data: {
      attributes: {
        ...snakecaseKeys(settings),
        ...coverAttributes(post),
        id: post.content.id,
        expired_at: post.settings.archiveAt?.toISO() || null,
        version: 2,
        content_blocks: {
          blocks: post.blocks.map(definitionToData),
          styles: post.styles,
          meta: { program_id: programId, ...variables },
        },
        content_channels: post.settings.contentTopics,
        library_template_id: post.content.libraryTemplateId,
        attachments: extractAttachmentInfo(post),
        content_note: post.content.contentNote,
        is_hidden: post.content?.isHidden,
      },
    },
  };
}

export type UpsertFetchProps = {
  url: string;
  body: ReturnType<typeof upsertBody>;
  method?: string;
  successCode?: number;
};

export const upsertFetch = async (
  props: UpsertFetchProps
): Promise<ServerPost> => {
  const { url, body, method = 'POST', successCode = 200 } = props;

  // The UpsertFetchProps type doesn't capture everything that can be in the body
  // hence the cast is required here
  /* eslint-disable */
  const attributes = props.body.data.attributes as any;

  if (
    !attributes.content_communication.audiences ||
    !('criterion' in attributes.content_communication)
  ) {
    datadogRum.addError(
      new Error('Malformed content upsert request'),
      props.body
    );
  }
  /* eslint-enable */

  try {
    const response = await request(url, {
      method,
      body: JSON.stringify(body),
      headers: postHeaders(),
    });

    if (response.status === successCode) {
      return response.json().then(transformPostResponse);
    }

    const errorResponse = await response.json();
    throw new Error(errorResponse.errors.join('\n'));
  } catch (error) {
    if (error instanceof ValidationError) {
      datadogRum.addError(new Error('422 content upsert response'), props.body);
    }

    throw error;
  }
};

export const createPost = async (props: UpsertType): Promise<ServerPost> => {
  return upsertFetch({
    url: `${bossanovaDomain}/samba/programs/${props.programId}/contents`,
    body: upsertBody(props.post, props.programId, props.variables),
    successCode: 201,
  });
};

export const updatePost = async (
  props: UpsertType,
  options: { isAutosave: boolean } = { isAutosave: false }
): Promise<ServerPost> => {
  // Uses a defensive auto-save endpoint while we track down the unexpected path.
  let url = `${bossanovaDomain}/samba/programs/${props.programId}/contents/${props.post.content.id}`;
  const body: ReturnType<typeof upsertBody> & { id?: number } = upsertBody(
    props.post,
    props.programId,
    props.variables
  );
  if (options.isAutosave) {
    url += '/autosave';
    body.id = props.post.content.id;
  }
  const method = options.isAutosave ? 'POST' : 'PUT';
  return upsertFetch({ url, body, method });
};

export const upsert = async (
  props: UpsertType,
  options: { isAutosave: boolean } = { isAutosave: false }
): Promise<Post> => {
  let data;

  if (props.post.content.id !== 0) {
    data = await updatePost(props, options);
  } else {
    data = await createPost(props);
  }

  const post = await mapServerDataToPost(props.programId, data);

  if (!post) throw new Error('Error while saving post');

  return post;
};

export const erasePost = async (props: PostRequestType): Promise<boolean> => {
  const response = await request(
    `${bossanovaDomain}/samba/programs/${props.programId}/contents/${props.postId}/erase`,
    {
      body: JSON.stringify({ id: props.postId, program_id: props.programId }),
      method: 'POST',
      headers: postHeaders(),
    }
  );

  if (response.status === 200) {
    return true;
  }

  const errorResponse = await response.json();
  throw new Error(errorResponse.errors.join('\n'));
};

export type StateAction =
  | 'draft'
  | 'archive'
  | 'publish'
  | 'erase'
  | 'duplicate';

export type ChangeState = {
  id: number;
  programId: number;
  action: StateAction;
};

export const changeState = async (props: ChangeState): Promise<ServerPost> => {
  const response = await request(
    `${bossanovaDomain}/samba/programs/${props.programId}/contents/${props.id}/${props.action}`,
    {
      body: JSON.stringify({ id: props.id }),
      method: 'POST',
      headers: postHeaders(),
    }
  );

  if (response.status !== 200) {
    throw new Error(
      `Error while changing state of content: ${response.status}`
    );
  }

  return response.json().then(transformPostResponse);
};

export const sendTestEmail = async (
  subject: string,
  previewText: string,
  post: Post,
  variables: RenderingVariables &
    Pick<RenderingContext['meta'], 'content_permalink'>,
  programId: number,
  recipients: number[],
  preferOutlook365: boolean
): Promise<void> => {
  if (!post.content.id) {
    throw new Error('Error: post is not persisted');
  }

  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/content_preview`,
    {
      body: JSON.stringify({
        blocks: post.blocks,
        styles: post.styles,
        meta: variables,
        subject,
        preview_text: previewText,
        type: 'email',
        program_contact_address_id: post.settings.emailSenderAlias?.id,
        recipients,
        content_id: post.content.id,
        preferOutlook365,
      }),
      method: 'POST',
      headers: postHeaders(),
    }
  );

  if (response.status !== 200) {
    throw new Error('Error sending preview email');
  }
};

export const sendTestPush = async (
  subject: string,
  previewText: string,
  post: Post,
  programId: number,
  recipients: number[]
): Promise<void> => {
  if (!post.content.id) {
    throw new Error('Error: post is not persisted');
  }

  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/content_preview`,
    {
      body: JSON.stringify({
        subject,
        preview_text: previewText,
        type: 'push',
        recipients,
      }),
      method: 'POST',
      headers: postHeaders(),
    }
  );

  if (response.status !== 200) {
    throw new Error('Error sending preview push');
  }
};

export async function fetchDefaultAuthor(programId: number): Promise<Author> {
  const response = await request(
    `${bossanovaDomain}/samba/programs/${programId}/contents/new`
  );
  const body = await response.json().then(deepCamelcaseKeys);
  return body.defaultAuthor as Author;
}

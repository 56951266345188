import React, { useCallback, useMemo } from 'react';
import { DesignComponent } from 'components/publisher/theme/Design/Component';
import { Redirect, Router } from '@reach/router';
import { Design } from 'components/publisher/pages/Design';
import { useLibraryFontsCollection } from 'hooks/use-library-fonts-collection';
import { Preview } from 'components/publisher/pages/Preview';
import { DesignError, useDesignContext } from 'contexts/design';
import { useDesignHooks } from 'components/publisher/theme/Design/useDesignHooks';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { PublisherType } from 'models/library';
import { SplitFooter } from './SplitFooter';

export const Designer: React.FC = () => {
  const { data: fonts, isLoading: fontsLoading } = useLibraryFontsCollection();
  const { id: programId } = useProgram();

  const fontOptions = useMemo(() => {
    return fonts
      .filter((i) => i.is_enabled_for_program)
      .map((i) => {
        return {
          label: i.title,
          value: i.asset.value,
          id: i.id,
          url: i.asset.css?.url,
          is_enabled_for_program: i.is_enabled_for_program,
        };
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [fonts]);

  const designContext = useDesignContext();
  const designEditor = useDesignHooks({
    design: designContext.design,
    fontOptions,
    update: designContext.update,
    publisherType: PublisherType.journeys,
  });

  const { setFlashMessage } = useFlashMessage();
  const onSuccess = useCallback(() => {
    setFlashMessage({
      message: 'Design saved successfully',
      severity: 'info',
    });
  }, [setFlashMessage]);
  const onError = useCallback(
    (errors) => {
      setFlashMessage({
        message: `Error persisting design: ${errors
          .map((e: DesignError) => e.detail)
          .join(', ')}`,
        severity: 'error',
      });
    },
    [setFlashMessage]
  );

  const saveAndClose = React.useCallback(() => {
    designContext.save({ onSuccess, onError });
  }, [designContext, onSuccess, onError]);

  const navigationComponent = (
    <SplitFooter
      actionName="Save"
      canPerformAction
      exitPath={`/${programId}/app/designs`}
      action={saveAndClose}
    />
  );

  if (fontsLoading) {
    return null;
  }

  return (
    <Router>
      <DesignComponent
        path="/"
        designPermission={{ canEdit: true, errors: [], isLoading: false }}
        designEditor={designEditor}
        navigationComponent={navigationComponent}
      >
        <Design path="design" implementation="new" />
        <Preview hideEmailPreview path="preview" />
        {/* The defaults for the directory */}
        <Redirect from="/" to="design" noThrow default />
      </DesignComponent>
    </Router>
  );
};

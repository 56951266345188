import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import useResizeObserver from 'use-resize-observer';
import cx from 'classnames';
import styles from './navigation-bars.module.css';

export enum StatusColor {
  draft = 'var(--color-blueTintDark)',
  active = 'var(--color-greenTintDarkAlt)',
  archived = 'var(--color-gray10)',
  publishing = 'var(--color-blueTintDark)',
  paused = '#fffbe8',
}

export type TitleBarStatus = {
  status: string;
  statusColor: StatusColor;
};

export const StatusTag: React.FC<{ status: TitleBarStatus }> = ({ status }) => {
  return (
    <Flex
      className={styles.statusTag}
      style={{ backgroundColor: status.statusColor }}
    >
      {status.status}
    </Flex>
  );
};

export const EditableTitle: React.FC<{
  title: string;
  updateTitle: (title: string) => void;
  maxLength?: number;
  defaultTitle?: string;
}> = ({ title, updateTitle, maxLength, defaultTitle = 'untitled' }) => {
  const labelRef = React.useRef<HTMLDivElement>(null);

  const [titleValue, setTitleValue] = React.useState(title);
  const [width, setWidth] = React.useState(100);
  const [isEditing, setIsEditing] = React.useState(false);

  const onResize = () => {
    if (!labelRef.current) return;
    setWidth(labelRef.current.offsetWidth);
  };

  useResizeObserver({ ref: labelRef, onResize });

  const handleChange: React.FocusEventHandler<HTMLInputElement> = (evt) => {
    const value = maxLength
      ? evt.currentTarget.value.slice(0, maxLength)
      : evt.currentTarget.value;
    setTitleValue(value);
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (titleValue !== title) {
      const isBlankTitle = !titleValue?.trim().length;
      if (isBlankTitle) {
        setTitleValue(defaultTitle);
        updateTitle(defaultTitle);
      } else {
        updateTitle(titleValue);
      }
    }
  };

  const labelClasses = cx(styles.editableTitleLabel, {
    [styles.editableTitleLabelHidden]: isEditing,
  });

  const inputClasses = cx(styles.editableTitleInput, {
    [styles.editableTitleInputHidden]: !isEditing,
  });

  return (
    <div className={styles.editableTitle}>
      <div ref={labelRef} className={labelClasses}>
        {titleValue}
      </div>
      <input
        type="text"
        value={titleValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={inputClasses}
        style={{ width }}
        maxLength={maxLength}
        title={titleValue}
      />
    </div>
  );
};

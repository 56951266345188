import { Flex } from 'DesignSystem/Layout/Flex';
import React from 'react';
import { CloseButton } from 'shared/NavigationBars/CloseButton';
import { UpperBar, LowerBar } from 'shared/NavigationBars/FixedBars';
import { NavigationFooter } from 'shared/NavigationFooter';
import * as Type from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import { EditableTitle } from 'shared/NavigationBars/utils';
import { useDesignContext } from 'contexts/design';
import styles from './styles.module.css';

type NavigationBarsProps = React.ComponentProps<typeof NavigationFooter> & {
  isErrorModalOpen: boolean;
  setIsErrorModalOpen: (value: boolean) => void;
  setErrorMessage: (value?: string) => void;
};

export const NavigationBars: React.FC<NavigationBarsProps> = ({
  exitHandler,
  action,
  canPerformAction,
  setIsErrorModalOpen,
  setErrorMessage,
}) => {
  const { design, update } = useDesignContext();
  const isNameValid = React.useMemo(() => {
    return design.name && design.name.length > 0;
  }, [design.name]);

  const handleClose = React.useCallback(() => {
    if (exitHandler) exitHandler();
  }, [exitHandler]);

  const saveAndClose = React.useCallback(() => {
    if (isNameValid) {
      if (action) {
        action();
      }
    } else {
      setErrorMessage(
        'Content name is required to save. Name your Content and try saving again.'
      );
      setIsErrorModalOpen(true);
    }
  }, [action, isNameValid, setErrorMessage, setIsErrorModalOpen]);

  const updateDesignName = React.useCallback(
    (name: string) => {
      update({ ...design, name });
    },
    [design, update]
  );

  return (
    <>
      <UpperBar>
        <Flex>
          <Type.Subheading color={Type.color.gray40}>
            Create Content
          </Type.Subheading>
          <EditableTitle
            title={design.name || 'Untitled'}
            updateTitle={updateDesignName}
            maxLength={100}
            defaultTitle="Untitled"
          />
        </Flex>
        <Flex end>
          <CloseButton handleClose={handleClose} />
        </Flex>
      </UpperBar>
      <LowerBar>
        <Flex className={styles.footer} end>
          <Button
            label="Save and Close"
            onClick={saveAndClose}
            disabled={!canPerformAction}
          />
        </Flex>
      </LowerBar>
    </>
  );
};
